/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { useEffect, useState } from 'react';

import Loading from '@domui-components/Loading';
import Metadata from 'src/domui/utils/workflow/metadata';

const ExpertiseLevels: React.FC = () => {
  const [expertiseLevels, setExpertiseLevels] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const metawfService = new Metadata();

      const expertise = await metawfService.getExpertiseLevels();
      setExpertiseLevels(expertise);
    };
    fetchData();
  }, []);

  if (!expertiseLevels) {
    return <Loading />;
  }

  return (
    <div>
      {expertiseLevels.map((expertise: any) => (
        <li key={expertise.id}>
          {expertise.id} - {expertise.value}
        </li>
      ))}
    </div>
  );
};

export default ExpertiseLevels;
